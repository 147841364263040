import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";

const AboutPage = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "800px",
        margin: "20px auto",
        textAlign: "center",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography variant="h4" gutterBottom>
        About This Project
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        Welcome to the Documyze AI! This website is a proof-of-concept application created
        to demonstrate how GenAI can be used to analyze legal and other documents effectively. 
        It's built using React and utilizes Anthropic's API for document analysis.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        Feel free to get in touch if you'd like to learn more about this
        project. You can connect with me on LinkedIn or reach out directly.
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: "10px" }}
          component={Link}
          href="https://www.linkedin.com/in/yacineouarab/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Connect With Me via LinkedIn
        </Button>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          href="mailto:yacine@ouarab.com"
        >
          Contact Me via Email
        </Button>
      </Box>
    </Box>
  );
};

export default AboutPage;
