import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Navbar = ({ resetAppState }) => {
  return (
    <AppBar
      position="static"
      sx={{
        background: "linear-gradient(90deg, #007BFF, #00C6FF)", // Gradient background
        padding: "10px 20px",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Logo and Brand */}
        <Box
          component={RouterLink}
          to="/"
          onClick={resetAppState}
          sx={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.1)", // Hover effect to make slightly bigger
            },
          }}
        >
          <Box
            component="img"
            src="/documyze-cropped.png" // Path to the logo
            alt="Documyze AI Logo"
            sx={{
              width: "40px", // Scale the logo size
              height: "40px",
              marginRight: "10px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "1.5em",
              color: "white",
              textDecoration: "none",
            }}
          >
            Documyze AI
          </Typography>
        </Box>

        {/* Navigation Links */}
        <Box
          sx={{
            display: "flex",
            gap: "15px",
          }}
        >
          <Typography
            component={RouterLink}
            to="/about"
            sx={{
              color: "white",
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "1rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            About
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
