import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Spreadsheet from "./Spreadsheet";
import * as mammoth from "mammoth";
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography } from "@mui/material";


const MainPage = () => {
  const [documents, setDocuments] = useState([]);
  const [isVerified, setIsVerified] = useState(true); // ReCaptcha verification state
  const [showDialog, setShowDialog] = useState(false);
  const fileUploadRef = useRef(null);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const parsedDocs = await parseFiles(files);
    setDocuments(parsedDocs);
  };

  const handleDemoClick = async () => {
    const demoFiles = [
      new File([await fetchBlob("/docs/tyosopimus-kainuun-hyvinvointialue.docx")], "tyosopimus-kainuun-hyvinvointialue.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }),
      new File([await fetchBlob("/docs/tyosopimus-kainuun-hyvinvointialue.pdf")], "tyosopimus-kainuun-hyvinvointialue.pdf", {
        type: "application/pdf",
      }),
      new File([await fetchBlob("/docs/Testityösopimus-hotelli-3.pdf")], "Testityösopimus-hotelli-3.pdf", {
        type: "application/pdf",
      }),
      new File([await fetchBlob("/docs/Testityösopimus-apteekki.pdf")], "Testityösopimus-apteekki.pdf", {
        type: "application/pdf",
      }),
      new File([await fetchBlob("/docs/Microsoft-Word-Tslom.dot.pdf")], "Microsoft-Word-Tslom.dot.pdf", {
        type: "application/pdf",
      }),
    ];

    const parsedDocs = await parseFiles(demoFiles);
    setDocuments(parsedDocs);
  };

  const fetchBlob = async (filePath) => {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Failed to fetch demo file: ${filePath}`);
    }
    return await response.blob();
  };

  const parseFiles = async (files) => {
    const acceptedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const parsedDocs = [];

    for (let file of files) {
      if (!acceptedTypes.includes(file.type)) {
        alert(`${file.name} is not a supported file type.`);
        continue;
      }

      const content = await parseFile(file);
      parsedDocs.push({ name: file.name, content, rawFile: file });
    }

    return parsedDocs;
  };

  const parseFile = async (file) => {
    if (file.type === "application/pdf") {
      return await parsePDF(file);
    } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return await parseDOCX(file);
    }
    return null;
  };

  const parsePDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let text = "";

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map((item) => item.str).join(" ");
      text += pageText + "\n";
    }

    return text;
  };

  const parseDOCX = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const handleDialogClose = (confirmed) => {
    setShowDialog(false);
    if (confirmed) {
      document.getElementById("fileInput").click();
    }
  };

  return (
    <div>
      {/* File Upload Section */}
      <div ref={fileUploadRef} style={{ padding: "20px", textAlign: "center" }}>
        {documents.length === 0 ? (
          <div
            style={{
              border: "2px dashed #007BFF",
              borderRadius: "10px",
              padding: "50px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <h2>Drag and drop files here</h2>
            <button
              style={{
                display: "block",
                margin: "10px auto",
                padding: "10px 20px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => setShowDialog(true)}
            >
              Upload your own documents
            </button>
            <button
              style={{
                display: "block",
                margin: "10px auto",
                padding: "10px 20px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleDemoClick}
            >
              Test with demo documents
            </button>
            <input
              id="fileInput"
              type="file"
              multiple
              style={{ display: "none" }}
              accept=".pdf,.docx"
              onChange={handleFileUpload}
            />
          </div>
        ) : (
          <Spreadsheet documents={documents} />
        )}
      </div>

      {/* Disclaimer Dialog */}
      <Dialog open={showDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Data Usage Disclaimer</DialogTitle>
        <DialogContent>
          Uploaded documents will be sent to Anthropic's cloud services for analysis.
          Please confirm that you are aware of and consent to this process before proceeding.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainPage;
