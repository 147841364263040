import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Lander = () => {
  const navigate = useNavigate();

  const handleCTA = () => {
    navigate("/app");
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#000",
        color: "#fff",
      }}
    >
      <video
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      >
        <source src="/document-studying.mp4" type="video/mp4" />
      </video>
      <Box sx={{ zIndex: 1, maxWidth: "800px" }}>
        <Typography variant="h3" gutterBottom>
          Save Time and Money with Documyze AI
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          Powered by GenAI, Documyze streamlines batch document analysis for lawyers, M&A experts,
          and private equity professionals.

          How much is your time worth? Studies show analyzing
          documents manually can take up to 10 hours per 100 pages. Documyze
          reduces that to just minutes.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCTA}
          sx={{ fontSize: "1.2rem", padding: "10px 20px" }}
        >
          Try Documyze Now
        </Button>
      </Box>
    </Box>
  );
};

export default Lander;
