import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4"; // Import Google Analytics library
import Navbar from "./components/Navbar";
import MainPage from "./components/MainPage";
import AboutPage from "./components/AboutPage";
import Lander from "./components/Lander";

// Component to handle Google Analytics tracking
const AnalyticsTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on location change
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return children; // Render the app's child components
};

function App() {
  const [resetKey, setResetKey] = useState(0); // Key to force MainPage remount

  const resetAppState = () => {
    setResetKey((prevKey) => prevKey + 1); // Increment the key to trigger remount
  };

  useEffect(() => {
    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize("G-XXXXXXXXXX"); // Replace with your actual Measurement ID
  }, []);

  return (
    <Router>
      <Navbar resetAppState={resetAppState} />
      <AnalyticsTracker>
        <Routes>
          <Route path="/" element={<Lander/>} />
          <Route path="/app" element={<MainPage key={resetKey} />} />
          <Route path="/about" element={<AboutPage />} /> {/* About Route */}
        </Routes>
      </AnalyticsTracker>
    </Router>
  );
}

export default App;
