import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import { renderAsync } from "docx-preview";
import { Anthropic } from "@anthropic-ai/sdk";

const apiKey = "apikey"; // Replace with your API key
const anthropicClient = new Anthropic({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true,
});

const Spreadsheet = ({ documents }) => {
  const [columns, setColumns] = useState(["Document Name", ""]);
  const [rows, setRows] = useState(documents.map((doc) => [doc.name, ""]));
  const [loadingStates, setLoadingStates] = useState(
    documents.map(() => [false, false])
  );
  const [reasonings, setReasonings] = useState(
    documents.map(() => [""]) // Store reasonings for tooltips
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [previewContent, setPreviewContent] = useState("");
  const [docxContainer, setDocxContainer] = useState(null);

  useEffect(() => {
    if (drawerOpen && currentDocument) {
      if (currentDocument.rawFile.type === "application/pdf") {
        renderPDF(currentDocument.rawFile);
      } else if (
        currentDocument.rawFile.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        if (docxContainer) {
          renderDOCX(currentDocument.rawFile, docxContainer);
        }
      }
    }
  }, [drawerOpen, currentDocument, docxContainer]);

  const handlePromptChange = async (index, prompt) => {
    if (prompt.trim() === "") return;

    const newColumns = [...columns];
    newColumns[index] = prompt;

    const newRows = [...rows];
    const newReasonings = [...reasonings];
    const newLoadingStates = [...loadingStates];

    await Promise.all(
      newRows.map(async (row, rowIndex) => {
        newLoadingStates[rowIndex][index] = true;
        setLoadingStates([...newLoadingStates]);

        const { answer, reasoning } = await fetchAnthropicAPI(
          documents[rowIndex].content,
          prompt
        );

        row[index] = answer;
        newReasonings[rowIndex][index] = reasoning; // Store reasoning for tooltip
        newLoadingStates[rowIndex][index] = false;
        setLoadingStates([...newLoadingStates]);
      })
    );

    newColumns.push("");
    setColumns(newColumns);

    const updatedRows = newRows.map((row) => [...row, ""]);
    const updatedReasonings = newReasonings.map((reasoning) => [...reasoning, ""]);
    const updatedLoadingStates = newLoadingStates.map((state) => [...state, false]);

    setRows(updatedRows);
    setReasonings(updatedReasonings);
    setLoadingStates(updatedLoadingStates);
  };

  const fetchAnthropicAPI = async (content, prompt) => {
    try {
        const response = await anthropicClient.messages.create({
            model: "claude-3-5-sonnet-20241022",
            max_tokens: 1024,
            messages: [
                {
                    role: "user",
                    content: 
                    `
                        You are an advanced research assistant AI designed to answer questions based solely on provided document contents. 
                        Your primary goal is to provide accurate, concise answers while avoiding any form of hallucination or inference beyond the given information.
    
                        Here is the document you need to analyze:
    
                        <document_contents>
                        ${content}
                        </document_contents>
    
                        And here is the question you need to answer:
    
                        <user_question>
                        ${prompt}
                        </user_question>
    
                        Please follow these steps to formulate your response:
    
                        1. Carefully read and analyze the document contents.
                        2. Review the user's question.
                        3. Conduct your analysis inside <analysis> tags:
                        - List and number relevant quotes from the document that pertain to the question.
                        - For each quote, explicitly state whether it directly answers the question or provides context.
                        - Determine if the answer is explicitly present in the document.
                        - Consider whether the answer is a straightforward yes/no or requires more nuance.
                        - If multiple parts of the document are relevant, consider them all.
                        - Consider potential ambiguities or multiple interpretations of the question.
                        - If the information is not present, prepare to state that it's not available.
    
                        4. Formulate your answer according to these rules:
                        - Base your answer ONLY on the content of the provided document.
                        - Do not infer, assume, or add any information not explicitly stated in the document.
                        - Keep your answer concise, preferably a yes or no. Do not repeat the user question in your answer.
                        - If the information is not available in the document, clearly state this fact.
                        - Use the same language as the question in your response (e.g., if the question is in Finnish, answer in Finnish; if in English, answer in English).
    
                        5. Format your output as a JSON object with two keys: "reasoning" and "answer". The "reasoning" should contain your analysis process, and the "answer" should contain your final response without repeating the question. Do not use line breaks in your answer!
    
                        Here's an example of the desired output format:
    
                        """json
                        {
                        "reasoning": "Relevant quotes from the document: 1. "[quote 1]" - Directly answers the question2. "[quote 2]" - Provides context The document contains information about [topic of question]. The answer appears to be [yes/no/more nuanced]. Multiple parts of the document are relevant: [brief explanation if applicable] Potential ambiguities in the question: [explanation if applicable]",
                        "answer": "Yes/No/[Direct answer without repeating the original question]"
                        }
                        """
    
                        If the information is not available in the document, your output should look like this:
    
                        """json
                        {
                        "reasoning": "After carefully reviewing the document, I couldn't find any information about [topic of question]. There are no relevant quotes that address this specific question.",
                        "answer": "This information is not available in the provided document."
                        }
                        """
    
                        Now, please proceed with analyzing the document and answering the user's question. Remember to format your output as JSON.
                    ` }
            ],
            temperature: 0
          });
            console.log('response', response)
    
        try {
            const parsed = JSON.parse(response.content[0].text)
            return {
              answer: parsed?.answer || "No response",
              reasoning: parsed?.reasoning || "No reasoning provided",
            };            
        } catch (error) {
            console.log('parsing failed', error, response)
            return {answer: response.content[0].text, reasoning: "Parsing failed"}   
        }

    } catch (error) {
      console.error("Error fetching Anthropic API:", error);
      return { answer: "Error: "+error.message, reasoning: error.type };
    }
  };

  const handleDocumentClick = (doc) => {
    setDrawerOpen(true);
    setCurrentDocument(doc);
    setPreviewContent("");
  };

  const renderPDF = async (doc) => {
    const arrayBuffer = await doc.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

    let pdfHTML = "<div>";

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const imageData = canvas.toDataURL("image/png");
      pdfHTML += `<img src="${imageData}" style="margin-bottom: 10px; width: 100%;" />`;
    }

    pdfHTML += "</div>";
    setPreviewContent(pdfHTML);
  };

  const renderDOCX = async (doc, container) => {
    container.innerHTML = ""; // Clear previous content

    try {
      const arrayBuffer = await doc.arrayBuffer();
      console.log("Starting DOCX rendering...");
      await renderAsync(arrayBuffer, container);

      if (container.innerHTML.trim()) {
        console.log("DOCX rendering complete. Content added.");
      } else {
        console.error("DOCX rendering completed but no content was generated.");
        setPreviewContent("Failed to render DOCX document: No content rendered.");
      }
    } catch (error) {
      console.error("Error rendering DOCX:", error);
      setPreviewContent("Failed to render DOCX document.");
    }
  };

  const exportToCSV = () => {
    const csvContent = [
      columns.join(","),
      ...rows.map((row) => row.map((cell) => `"${cell}"`).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "document_analysis.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.aoa_to_sheet([columns, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Document Analysis");

    XLSX.writeFile(workbook, "document_analysis.xlsx");
  };

  return (
    <>
      <Paper sx={{ padding: 2, margin: "20px auto", maxWidth: "90%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
          <Typography variant="h6">Document Analysis</Typography>
          <Box>
            <Button variant="outlined" onClick={exportToCSV} sx={{ marginRight: 1 }}>
              Export to CSV
            </Button>
            <Button variant="outlined" onClick={exportToXLSX}>
              Export to XLSX
            </Button>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#f0f0f0",
                    minWidth: index === columns.length - 1 ? "150px" : "auto",
                  }}
                >
                  {index === 0 ? (
                    col
                  ) : index === columns.length - 1 ? (
                    <TextField
                      placeholder="Enter a new column!"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handlePromptChange(index, e.target.value);
                      }}
                    />
                  ) : (
                    col
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => {
                  if (cellIndex === 0) {
                    return (
                      <TableCell
                        key={cellIndex}
                        sx={{ textAlign: "center", cursor: "pointer", color: "blue" }}
                        onClick={() => handleDocumentClick(documents[rowIndex])}
                      >
                        {cell}
                      </TableCell>
                    );
                  }
                  return (
                    <Tooltip
                      key={cellIndex}
                      title={reasonings[rowIndex][cellIndex] || ""}
                      arrow
                      placement="top"
                    >
                      <TableCell key={cellIndex} sx={{ textAlign: "center" }}>
                        {loadingStates[rowIndex][cellIndex] ? (
                          <CircularProgress size={20} />
                        ) : (
                          cell
                        )}
                      </TableCell>
                    </Tooltip>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: 400 }}
      >
        <Box
          sx={{ padding: 2, width: 400 }}
          ref={(node) => setDocxContainer(node)} // Assign the callback ref
          className="docx-container"
        >
          {currentDocument?.rawFile.type === "application/pdf" && (
            <Box dangerouslySetInnerHTML={{ __html: previewContent }} />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default Spreadsheet;
